




































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import ProfileHeader from '@/components/Mobile/Profile/ProfileHeader.vue';
import StatsSection from '@/components/Mobile/Profile/StatsSection.vue';
import TourArchive from '@/components/Mobile/Profile/TourArchive.vue';
import FollowButton from '@/components/Mobile/FollowButton.vue';
import Bio from '@/components/Mobile/Profile/Bio.vue';
import ClubChatWidget from '@/club/chat/ClubChatWidget.vue';

export default Vue.extend({
  name: 'profile',
  components: {
    Scaffold,
    ProfileHeader,
    StatsSection,
    TourArchive,
    FollowButton,
    Bio,
    ClubChatWidget,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('toggleLoading', true);
    await dispatch('user/bindUserProfile', this.userId);
    await dispatch('user/fetchUserFollowingCount', this.userId);
    dispatch('toggleLoading', false);
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/getUserProfile',
      userFollowingCount: 'user/getUserFollowingCount',
    }),
  },
  methods: {
    ...mapMutations({
      resetProfileData: 'user/RESET_PROFILE',
      resetFollowingCount: 'user/RESET_FOLLOWING_COUNT',
    }),
  },
  beforeDestroy() {
    this.resetProfileData();
    this.resetFollowingCount();
    this.$store.dispatch('user/unbindUserProfile');
  },
});
